export const IMAGE_URL =
  process.env.REACT_APP_NODE_ENV === "production"
    ? "https://ws.maritima.fr/api/" : "http://localhost:4001/api/";
export const IMAGE_URL_WYSIWYG =
  process.env.REACT_APP_NODE_ENV === "production"
    ? "https://ws.maritima.fr/api/" : "http://localhost:4001/api/";
export const API_URL =
  process.env.REACT_APP_NODE_ENV === "production"
    ? "https://ws.maritima.fr/api_bo/" : "http://localhost:4001/api_bo/";
export const MARITIMMA_NEWSLETTER =
  process.env.REACT_APP_NODE_ENV === "production"
    ? "https://maritima.fr/" : "http://localhost:4001/";
