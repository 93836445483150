import React, {useEffect, useState} from "react";
import Menu from "../../components/layout/Menu";
import articlesService from "../../services/articles.service";
import {StripedDataGrid} from "../../constants/tableStyle";
import {frFR, GridToolbar} from "@mui/x-data-grid";
import {Container} from "@mui/material";
import AlertDialog from "../../components/Alert";
import Action from "../../components/Action";
import CircularProgress from "@mui/material/CircularProgress";
import {Grid} from "@mui/material";
import {toast} from "react-toastify";
import {
    CheckCircle,
    Error,
    HourglassEmpty,
    VisibilityOff,
} from "@mui/icons-material";
import {Typography} from "@mui/material";
import {useTheme, useMediaQuery} from "@mui/material";

export default function ArticlesTable() {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
    const [idNotification, setIdNotification] = useState(null);
    const [loader, setLoader] = useState(true);
    const [loaderNotification, setLoaderNotification] = useState(false);
    const [rows, setRows] = useState([]);
    const [openConfirmNotificationArticle, setOpenConfirmNotificationArticle] =
        useState(false);
    const handleOpenConfirmNotification = (id) => {
        setIdNotification(id);
        setOpenConfirmNotificationArticle(true);
    };
    const handleClosePopUp = () => {
        setOpenConfirmNotificationArticle(false);
    };

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        articlesService
            .getArticles(setRows, setLoader, signal)
            .catch((error) => console.log(error));

        return () => {
            controller.abort(); // Annule la requête si le composant est démonté
        };
    }, []);

    const [paginationModel, setPaginationModel] = useState({
        pageSize: 50,
        page: 0,
    });

    const deleteArticle = (id) => {
        articlesService.deleteArticle(id).then((response) => {
            if (response.data) {
                setRows(rows.filter((row) => row.id !== id));
            }
        });
        //TODO change with notification
    };
    const handleNotificationClick = () => {
        setLoaderNotification(true);
        articlesService
            .getNotificationsById(idNotification)
            .then((response) => {
                if (response.data) {
                    setLoaderNotification(false);
                    handleClosePopUp();
                    toast.success("Notification envoyée avec succès", {
                        position: "top-right",
                        theme: "dark",
                    });
                    setRows(
                        rows.map((row) => {
                            if (row.id === idNotification) {
                                return {...row, notification: response.data.nbNotification};
                            }
                            return row;
                        })
                    );
                } else {
                    handleClosePopUp();
                    toast.error("Échec de l'envoi de la notification", {
                        position: "top-right",
                        theme: "dark",
                    });
                    setLoaderNotification(false);
                }
            })
            .catch((error) => {
                handleClosePopUp();
                const errorMessage = error.response?.data?.message
                    ? error.response.data.message
                    : "";
                toast.error(
                    `Échec de l'envoi de la notification ${
                        errorMessage === "Article published not found"
                            ? "L'article n'est pas publié"
                            : ""
                    }`,
                    {
                        position: "top-right",
                        theme: "dark",
                    }
                );
            });
    };

    const isValidatedStatus = (isValidated, validateDate, is_active) => {
        if (isValidated === false && is_active === false) {
            return {status: "Brouillon", color: "#f44336", icon: <Error/>};
        } else if (is_active === true && isValidated === true) {
            return {
                status: "Publié",
                color: "#4caf50",
                icon: <CheckCircle sx={{color: "green"}}/>,
            };
        } else if (isValidated === true && validateDate == null) {
            return {
                status: "A valider",
                color: "#ffeb3b",
                icon: <HourglassEmpty/>,
            };
        } else {
            return {status: "Désactivé", color: "#9e9e9e", icon: <VisibilityOff/>};
        }
    };
    const StatusComponent = ({isValidated, validateDate, isActive}) => {
        const {status, color, icon} = isValidatedStatus(
            isValidated,
            validateDate,
            isActive
        );

        return (
            <div
                style={{
                    padding: "6px",
                    borderRadius: "5px",
                    display: "inline-flex",
                    alignItems: "center",
                }}
            >
                {icon}
                <Typography style={{marginLeft: "5px"}}>{status}</Typography>
            </div>
        );
    };

    const columns = [
        {
            field: "actions",
            headerName: "#",
            width: 110,
            renderCell: (params) => {
                
                return (

                    <Action
                        handleOpenConfirmNotification={handleOpenConfirmNotification}
                        notificationActivate={true}
                        editActivate={true}
                        setRows={setRows}
                        deleteAction={() => deleteArticle(params.id)}
                        rows={rows}
                        editLink={`/articles/${params.id}`}
                        idArticle={params.id}
                        notifications={params.row.notification}
                        label="Êtes-vous sûr de vouloir supprimer cet article ?"
                    />
                );
            },
        },
        {
            field: "title",
            headerName: "Titre",
            width: isSmallScreen ? 160 : 170,
            hideable: false,
        },
        {
            field: "themes",
            headerName: "Thèmes",
            width: 150,
            valueGetter: (params) => {
                return params.row.themes.map((theme) => theme.name);
            },
        },
        {
            field: "city",
            headerName: "Ville",
            width: 200,
            valueGetter: (params) => params.row.city.name,
        },
        {
            field: "createdAt",
            headerName: "Créé le",
            minWidth: 200,
            valueFormatter: (params) => {
                const date = new Date(params.value);
                const options = {
                    weekday: "long",
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                };
                let localDateString = date.toLocaleDateString("fr-FR", options);
                localDateString = localDateString.replace(
                    /(\w)(\w*)/g,
                    function (g0, g1, g2) {
                        return g1.toUpperCase() + g2.toLowerCase();
                    }
                ); // Up the first letter in Uppercase
                return localDateString;
            },
        },
        {
            field: "publication_date",
            headerName: "Date de publication",
            width: 200,
            sortable: true,
            valueGetter: (params) => {
                const dateStr = params.value
                const [day, month, yearTime] = dateStr.split('/');
                const [year, time] = yearTime.split(' ');
                const [hour, minute, second] = time.split(':');
                const date = new Date(`${year}-${month}-${day}T${hour}:${minute}:${second}`)
                return date
            },
            valueFormatter: (params) => {
                const date = new Date(params.value)
                const options = {
                    day: "numeric",
                    month: "numeric",
                    year: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric",
                };
                let localDateString = date.toLocaleDateString("fr-FR", options);// Up the first letter in Uppercase
                return localDateString;
            }
        },
        {
            field: "is_active",
            headerName: "Actif",
            width: 100,
            valueGetter: (params) => {
                return params.row.is_active ? "Oui" : "Non";
            },
        },
        {
            field: "status",
            headerName: "Status",
            width: 150,
            valueGetter: (params) => {
                const type = isValidatedStatus(params.row.is_validate, params.row.validate_date, params.row.is_active)
                switch (type.status) {
                    case "A valider":
                        return "A valider";
                        break;
                    case "Brouillon":
                        return "Brouillon";
                        break;
                    case "Désactivé":
                        return "Désactivé";
                        break;
                    default:
                        return "Publié";
                }
            },
            renderCell: (params) => {
                return (
                    <StatusComponent
                        isValidated={params.row.is_validate}
                        validateDate={params.row.validate_date}
                        isActive={params.row.is_active}
                    />
                );
            },
        },
        {
            field: "deleteArticle",
            headerName: "",
            width: 70,
            renderCell: (params) => {
                return (
                    <Action
                        trashIcon={true}
                        deleteAction={() => deleteArticle(params.id)}
                    />
                );
            },
        },

        {
            field: "description",
            headerName: "Description",
            width: 200,
        },
        {
            field: "body",
            headerName: "Body",
            width: 200,
        },

        {
            field: "is_validate",
            headerName: "Validé",
            width: 70,
            valueGetter: (params) => {
                return params.is_validate ? "Oui" : "Non";
            },
        },
        {
            field: "validate_date",
            headerName: "Date de validation",
            width: 200,
            valueFormatter: (params) => {
                const date = new Date(params.value);
                const options = {
                    weekday: "long",
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                };
                let localDateString = date.toLocaleDateString("fr-FR", options);
                localDateString = localDateString.replace(
                    /(\w)(\w*)/g,
                    function (g0, g1, g2) {
                        return g1.toUpperCase() + g2.toLowerCase();
                    }
                ); // Up the first letter in Uppercase
                return localDateString;
            },
        },

        {
            field: "updatedAt",
            headerName: "Date de modification",
            width: 200,
            valueFormatter: (params) => {
                const date = new Date(params.value);
                const options = {
                    weekday: "long",
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                };
                let localDateString = date.toLocaleDateString("fr-FR", options);
                localDateString = localDateString.replace(
                    /(\w)(\w*)/g,
                    function (g0, g1, g2) {
                        return g1.toUpperCase() + g2.toLowerCase();
                    }
                ); // Up the first letter in Uppercase
                return localDateString;
            },
        },

        {
            field: "authors",
            headerName: "Auteurs",
            width: 200,
            valueGetter: (params) => {
                return params.row.authors
                    .map((author) => author.firstname + " " + author.lastname)
                    .join(", ");
            },
        },
    ];

    const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({
        actions: true,
        title: true,
        description: false,
        body: false,
        publication_date: true,
        is_active: true,
        is_validate: false,
        validate_date: false,
        createdAt: true,
        updatedAt: false,
        city: true,
        themes: true,
        authors: false,
        status: true,
    });

    useEffect(() => {
        // Función para desactivar ciertas columnas en pantallas pequeñas
        const updateColumnVisibility = () => {
            if (isSmallScreen) {
                setColumnVisibilityModel((prevModel) => ({
                    ...prevModel,
                    description: false,
                    body: false,
                    publication_date: false,
                    is_active: false,
                    is_validate: false,
                    validate_date: false,
                    createdAt: false,
                    updatedAt: false,
                    city: false,
                    themes: false,
                    authors: false,
                    status: false,
                }));
            } else if (isMediumScreen) {
                setColumnVisibilityModel((prevModel) => ({
                    ...prevModel,
                    description: false,
                    body: false,
                    publication_date: false,
                    is_active: false,
                    is_validate: false,
                    validate_date: false,
                    createdAt: false,
                    updatedAt: false,
                    city: true,
                    themes: false,
                    authors: false,
                    status: true,
                }));
            } else {
                setColumnVisibilityModel((prevModel) => ({
                    ...prevModel,
                    actions: true,
                    title: true,
                    description: false,
                    body: false,
                    publication_date: true,
                    is_active: true,
                    is_validate: false,
                    validate_date: false,
                    createdAt: true,
                    updatedAt: false,
                    city: true,
                    themes: true,
                    authors: false,
                    status: true,
                }));
            }
        };

        updateColumnVisibility();
    }, [isSmallScreen]);

    const isValidated = (isValidated, validateDate, is_active) => {
        if (isValidated === false && is_active === false) {
            return "toRead";
        } else if (is_active === true) {
            return "published";
        } else if (
            isValidated === true &&
            validateDate !== null &&
            is_active === false
        ) {
            return "toPublish";
        } else {
            return "toValidate";
        }
    };
    const breadcrumbArray = [{name: "Articles", to: "/articles"}];

    return (
        <Menu
            category="Les articles"
            breadCrumb={breadcrumbArray}
            link="/articles/create"
            buttonText="Créer un article"
        >
            <AlertDialog
                open={openConfirmNotificationArticle}
                handleClose={handleClosePopUp}
                handleAgree={() => handleNotificationClick()}
                label={"Êtes-vous sûr de vouloir envoyer une notification?"}
                title="Envoyer une notification"
                buttonAccept="Envoyer"
                buttonCancel="Annuler"
                loaderNotification={loaderNotification}
            />
            <AlertDialog title="Supression d'articles"/>
            {loader ? (
                <Container className="d-flex justify-content-center">
                    <CircularProgress className="mx-5" color="inherit"/>
                </Container>
            ) : (
                <Grid container spacing={2}>
                    <Grid item xs={11.3} sm={12} md={12} lg={12}>
                        <StripedDataGrid
                            style={{maxWidth: "100%"}}
                            rows={rows}
                            columns={columns}
                            paginationModel={paginationModel}
                            onPaginationModelChange={setPaginationModel}
                            slots={{
                                toolbar: GridToolbar,
                            }}
                            // className="mx-3"
                            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                            getRowClassName={(params) => {
                                return isValidated(
                                    params.row.is_validate,
                                    params.row.validate_date,
                                    params.row.is_active
                                );
                                // return params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
                            }}
                            columnVisibilityModel={columnVisibilityModel}
                            onColumnVisibilityModelChange={(newModel) =>
                                setColumnVisibilityModel(newModel)
                            }
                            //   onRowClick={(params, event) => {
                            //     redirectOnPage(params.id);
                            //   }}
                        />
                    </Grid>
                </Grid>
            )}
        </Menu>
    );
}
