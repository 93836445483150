import * as React from 'react';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import instance from "../../../utils/axios";
import { Alert, Box } from "@mui/material";
import { Menu, MenuItem, IconButton } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import mediaService from "../../../services/media.service";
import ModalSuccess from '../../../components/ModalSuccess';



const filter = createFilterOptions();

export default function FreeSoloCreateOptionDialog({ value, setValue, listTag, getInputValue, reloadPage, setTagCreate, setDataArticle, dataArticle, setTagsId, tagsId }) {

    const [open, toggleOpen] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [editingTag, setEditingTag] = React.useState(null);
    const [isModalSuccessOpen, setIsModalSuccessOpen] = useState(false);


    const handleClose = () => {
        setError(false);
        setEditingTag(null);
        setDialogValue({
            name: '',
        });
        reloadPage();
        toggleOpen(false);
    };

    const [dialogValue, setDialogValue] = React.useState({
        name: '',
    });

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!editingTag) {
            instance.post('tags/create', dialogValue)
                .then((res) => {
                    if (res && res.status === 200 && res.data.create.id) {
                        if (dataArticle) {
                            setDataArticle({
                                ...dataArticle,
                                tag_id: [...dataArticle.tag_id, res.data.create.id]
                            })
                        }
                        setValue([...value, res.data.create])
                        setTagCreate(...listTag, res.data.create)
                        if (tagsId) {
                            setTagsId([...tagsId, res.data.create.id])
                        }
                        handleClose();
                    } else if (res.data.message) {
                        setError('Tag déjà existant')
                    } else {
                        setError('Une erreur est survenue')
                    }
                })
                .catch((err) => {
                    if (err.response?.data?.message && err.response?.data?.message === "Tag already exist") {
                        setError('Tag déjà existant')
                    } else {
                        setError('Une erreur est survenue')
                    }
                });
        } else {
            mediaService.updateTagById({ id: editingTag.id, data: dialogValue.name }).then((res) => {
                if (res && res.status === 200 && res.data.tag.id) {


                    const updatedListTag = listTag.map((tag) => {
                        if (tag.id === editingTag.id) {
                            return { ...tag, name: dialogValue.name };
                        }
                        return tag;
                    });

                    setTagCreate(updatedListTag);
                    setValue(value.map((tag) => {
                        if (tag.id === editingTag.id) {
                            return res.data.tag;
                        }
                        return tag;
                    }));
                    setIsModalSuccessOpen(true);
                    handleClose();
                }
            }

            ).catch((err) => {
                if (err.response?.data?.message && err.response?.data?.message === "Un tag avec ce nom existe déjà") {
                    setError('Tag déjà existant')
                } else {
                    setError('Une erreur est survenue')
                }
            }
            );

        }
    };

    const handleEditTag = (tag) => {
        setEditingTag(tag);
        setDialogValue({ name: tag.name.trim() });
        toggleOpen(true);
    };
    const [anchorEl, setAnchorEl] = useState(null);
    const [openMenuIndex, setOpenMenuIndex] = useState(null);

    const handleMenuOpen = (event, index) => {
        setAnchorEl(event.currentTarget);
        setOpenMenuIndex(index);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setOpenMenuIndex(null);
    };

    const handleDeleteTag = (tag) => {

        const newValue = value.filter((t) => t.id !== tag.id); // Filtrar los tags para eliminar el tag seleccionado
        setValue(newValue);
        setDataArticle({
            ...dataArticle,
            tag_id: dataArticle.tag_id.filter((t) => t !== tag.id)
        })

        handleMenuClose();
    };

    return (
        <React.Fragment>
            <Autocomplete
                multiple
                size={'small'}
                value={value}
                onChange={(event, newValue) => {
                    if (newValue && Array.isArray(newValue) && newValue.length > 0) {
                        let arrayIdValue = [];
                        let arrayValue = [];
                        for (const newValueElement of newValue) {
                            if (typeof newValueElement === 'string') {
                                // timeout to avoid instant validation of the dialog's form.
                                setTimeout(() => {
                                    toggleOpen(true);
                                    setDialogValue({
                                        name: newValueElement.trim(),
                                    });
                                });
                            } else if (newValueElement && newValueElement.inputValue) {
                                toggleOpen(true);
                                setDialogValue({
                                    name: newValueElement.inputValue.trim(),
                                });
                            } else {
                                if (newValueElement && newValueElement.id) {
                                    arrayValue.push(newValueElement)
                                    arrayIdValue.push(newValueElement.id)
                                }
                            }
                        }
                        setValue(arrayValue);
                        getInputValue(arrayIdValue);
                    } else {
                        setValue([]);
                        getInputValue([]);
                    }
                }}
                filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    if (params.inputValue !== '') {
                        filtered.push({
                            inputValue: params.inputValue,
                            name: `Ajouter "${params.inputValue}"`,
                        });
                    }
                    return filtered;
                }}
                id="free-solo-dialog-demo"
                options={listTag}
                getOptionLabel={(option) => {
                    if (option.inputValue) {
                        return option.inputValue;
                    }
                    if (option.name) {
                        return option.name;
                    }
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                renderOption={(props, option) => (
                    <li {...props} style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                            {option.name}
                        </span>
                        <IconButton
                            size="small"
                            color="primary"
                            onClick={(e) => {
                                e.preventDefault();
                                handleEditTag(option);
                            }}
                            style={{ marginLeft: '8px' }}
                        >
                            <EditIcon />
                        </IconButton>
                    </li>
                )}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                        <Box key={option.id} sx={{ display: 'flex', alignItems: 'center', borderRadius: 16, bgcolor: '#e5e7eb', pr: 0, m: 0.5 }}>
                            <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', marginLeft: 12, padding: 0, fontSize: 13 }}>
                                {option.name.slice(0, 20) + (option.name.length > 20 ? "..." : "")}
                            </span>
                            <IconButton
                                size="small"
                                color="primary"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleMenuOpen(e, index);
                                }}
                            >
                                <MoreVertIcon fontSize="small" />
                            </IconButton>
                            <Menu
                                anchorEl={anchorEl}
                                open={openMenuIndex === index}
                                onClose={handleMenuClose}

                            >
                                <MenuItem onClick={() => handleEditTag(option)}>Éditer</MenuItem>
                                <MenuItem onClick={() => handleDeleteTag(option)}>Supprimer</MenuItem>
                            </Menu>
                        </Box>
                    ))
                }
                sx={{ width: "100%" }}
                freeSolo
                renderInput={(params) => <TextField {...params} label="" />}
            />
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
                <form onSubmit={handleSubmit}>
                    <DialogTitle>{editingTag ? "Éditer un tag" : "Ajouter un nouveau tag"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {editingTag ? "Modifier le tag existant" : "Cela va créer un nouveau tag utilisable"}
                        </DialogContentText>
                        {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            value={dialogValue.name}
                            onChange={(event) =>
                                setDialogValue({
                                    ...dialogValue,
                                    name: event.target.value.trim(),
                                })
                            }
                            label="Tag"
                            type="text"
                            variant="outlined"
                            fullWidth
                        />
                    </DialogContent>
                    <DialogActions sx={{ pb: 2 }}>
                        <Button onClick={handleClose} variant="outlined">Annuler</Button>
                        <Button type="submit" variant="contained" color="primary">
                            {editingTag ? "Modifier" : "Ajouter"}
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
            <ModalSuccess title={"Succès"} description={"Le tag a été modifié avec succès"} open={isModalSuccessOpen} onClose={() => setIsModalSuccessOpen(false)} />

        </React.Fragment>
    );
}
