import React, {useEffect} from 'react'
import Menu from '../../components/layout/Menu'
import {Grid, Container, CircularProgress} from '@mui/material'
import {StripedDataGrid} from '../../constants/tableStyle'
import {frFR, GridToolbar} from '@mui/x-data-grid'
import AlertDialog from '../../components/Alert'
import Action from '../../components/Action'
import {useState} from 'react'
import {useParams} from 'react-router-dom'
import tvService from "../../services/tv.services";
import createColumnsTV from './columsForListTv'
import podcastService from "../../services/podcast.services";
import articlesService from "../../services/articles.service";
import {toast} from "react-toastify";


export const TvAnsPodcastTableList = () => {

    const {podcastGroupId} = useParams(); // Récupère l'ID de l'URL
    const {tvGroupId} = useParams(); // Récupère l'ID de l'URL
    const linkMenuCreate = podcastGroupId ? `/podcast/${podcastGroupId}/create` : `/tv/${tvGroupId}/create`;
    const category = podcastGroupId ? "Podcast" : "TV";
    const buttonText = podcastGroupId ? "Créer un Podcast" : "Créer une TV";
    const [shows, setShows] = useState([]);
    const [loader, setLoader] = useState(false);
    const [idNotification, setIdNotification] = useState(null);
    const [openConfirmNotificationArticle, setOpenConfirmNotificationArticle] =
        useState(false);
    const [loaderNotification, setLoaderNotification] = useState(false);


    //breadCrumb 
    const findShowNameById = (id) => {
        const show = shows.find((show) => show.id === Number(id));
        if (tvGroupId) {
            return show ? show.title : "";
        } else {
            return show ? show.name : "";
        }
    };

    let breadcrumbArray = [
        {name: "Tableau de bord", to: "/dashboard"},
        {name: "Podcast", to: "/podcast"},
        {name: `${findShowNameById(podcastGroupId)} `, to: "/podcast:id"},
    ];
    if (tvGroupId) {
        breadcrumbArray = [
            {name: "Tableau de bord", to: "/dashboard"},
            {name: "TV", to: "/tv"},
            {name: `${findShowNameById(tvGroupId)}`, to: "/tv:id"},
        ];
    }

    const [rows, setRows] = useState([]);

    useEffect(() => {
        if (tvGroupId) {
            setLoader(true);
            tvService.getAllTvReplayByTvShow(tvGroupId).then((response) => {
                console.log(response)
                const sortedRows = response.data.sort((a, b) => {
                    const dateA = new Date(convertToISO(a.publication_date));
                    const dateB = new Date(convertToISO(b.publication_date));
                    return dateB - dateA;
                });
                setRows(sortedRows);
                setLoader(false);
            });
            tvService.getTvShow().then((response) => {
                if (response.data) {
                    setShows(
                        response.data
                    )
                }
            })
        }
        if (podcastGroupId) {
            setLoader(true);
            podcastService.getAllPodcastByShow(podcastGroupId).then((response) => {
                setRows(response.data);
                setLoader(false);
            });
            podcastService.getPodcastShows().then((response) => {
                if (response.data) {
                    setShows(
                        response.data
                    )
                }
            })
        }
    }, []);

    // fonction de mise en forme des dates recues depuis les WS
    const convertToISO = (dateStr) => {
        const [day, month, yearTime] = dateStr.split('/');
        const [year, time] = yearTime.split(' ');
        const [hour, minute, second] = time ? time.split(':') : ['00', '00', '00'];
        return `${year}-${month}-${day}T${hour}:${minute}:${second}`;
    };

    const [paginationModel, setPaginationModel] = useState({
        pageSize: 50,
        page: 0,
    });
    const editLink = podcastGroupId ? `/podcast/edit/${podcastGroupId}` : `/tv/edit/${tvGroupId}`;


    const columnsPodcast = [
        {
            field: "actions",
            headerName: "#",
            width: 130,
            renderCell: (params) => {

                return (
                    <Action
                        // notificationActivate={true}
                        editActivate={true}
                        setRows={setRows}
                        rows={rows}
                        editLink={`${editLink}/${params.id}`}
                        idArticle={params.id}
                        notifications={0}
                        label="Êtes-vous sûr de vouloir supprimer cet article ?"
                    />
                );
            },
        },
        {
            field: "title",
            headerName: "Titre",
            width: 380,
            hideable: false
        },
        {
            field: "total_time",
            headerName: "Durée",
            width: 250,
            valueGetter: (params) => params.row.total_time
        },
        {
            field: "is_active",
            headerName: "Afficher",
            width: 300,
            valueGetter: (params) => params.row.is_active ? "Oui" : "Non"
        },
        {
            field: "delete",
            headerName: "",
            width: 70,
            renderCell: (params) => {
                return (
                    <Action
                        trashIcon={true}
                        deleteAction={() => handleDeleteArticle(params.id)}// to finish when ws is finish

                    />
                );
            },
        },

    ];
    const handleDeleteArticle = (id) => {
        if (category === "TV") {
            tvService.deleteTvReplay(id)
                .then((res) => {
                    if (res.status === 200) {
                        setRows(rows.filter((row) => row.id !== id));
                    }
                })
                .catch((err) => {
                    console.log(err);
                })
        }

        if (category === "Podcast") {
            podcastService.deletePodcast(id)
                .then((res) => {
                    if (res.status === 200) {
                        setRows(rows.filter((row) => row.id !== id));
                    }
                })
                .catch((err) => {
                    console.log(err);
                })
        }
    }

    const columnsTV = createColumnsTV({
        setRows,
        rows,
        editLink,
        handleDeleteArticle,
        idNotification,
        setIdNotification,
        openConfirmNotificationArticle,
        setOpenConfirmNotificationArticle
    });

    const handleClosePopUp = () => {
        setOpenConfirmNotificationArticle(false);
    }

    const handleNotificationClick = () => {
        setLoaderNotification(true);
        tvService
            .sendNotificationById(idNotification)
            .then((response) => {
                if (response.data) {
                    setLoaderNotification(false);
                    handleClosePopUp();
                    toast.success("Notification envoyée avec succès", {
                        position: "top-right",
                        theme: "dark",
                    });
                    setRows(
                        rows.map((row) => {
                            if (row.id === idNotification) {
                                return {...row, notification: response.data.nbNotification};
                            }
                            return row;
                        })
                    );
                } else {
                    handleClosePopUp();
                    toast.error("Échec de l'envoi de la notification", {
                        position: "top-right",
                        theme: "dark",
                    });
                    setLoaderNotification(false);
                }
            })
            .catch((error) => {
                handleClosePopUp();
                const errorMessage = error.response?.data?.message
                    ? error.response.data.message
                    : "";
                toast.error(
                    `Échec de l'envoi de la notification ${
                        errorMessage === "Article published not found"
                            ? "L'article n'est pas publié"
                            : ""
                    }`,
                    {
                        position: "top-right",
                        theme: "dark",
                    }
                );
            });
    };

    return (
        <Menu category={`${category} : ${findShowNameById(podcastGroupId ? podcastGroupId : tvGroupId)}`}
              breadCrumb={breadcrumbArray} link={linkMenuCreate} buttonText={buttonText}>
            <AlertDialog title="Supression d'articles"/>
            <AlertDialog
                open={openConfirmNotificationArticle}
                handleClose={handleClosePopUp}
                handleAgree={() => handleNotificationClick()}
                label={"Êtes-vous sûr de vouloir envoyer une notification?"}
                title="Envoyer une notification"
                buttonAccept="Envoyer"
                buttonCancel="Annuler"
                loaderNotification={loaderNotification}
            />
            {loader ? (
                <Container className="d-flex justify-content-center">
                    <CircularProgress className="mx-5" color="inherit"/>
                </Container>
            ) : (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <StripedDataGrid
                            style={{maxWidth: "100%"}}
                            rows={rows}
                            columns={tvGroupId ? columnsTV : columnsPodcast}
                            paginationModel={paginationModel}
                            onPaginationModelChange={setPaginationModel}
                            slots={{
                                toolbar: GridToolbar,
                            }}
                            className="mx-3"
                            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                        />
                    </Grid>
                </Grid>

            )}
        </Menu>
    )
}
