import React, {useEffect} from 'react'
import {Grid, Container, CircularProgress, Alert} from '@mui/material'
import {StripedDataGrid} from '../../../constants/tableStyle'
import {frFR, GridToolbar} from '@mui/x-data-grid'
import AlertDialog from '../../../components/Alert'
import Action from '../../../components/Action'
import {useState} from 'react'
import agendaService from "../../../services/agenda.service";
import {toast} from "react-toastify";
import {Typography} from '@mui/material';
import {CheckCircle, Error} from '@mui/icons-material';
import Switch from "@mui/material/Switch";
import {AlertTitle} from "@mui/lab";


const AgendaListEvents = () => {
    const [openConfirmNotificationAgenda, setOpenConfirmNotificationAgenda] = useState(false);
    const [idNotification, setIdNotification] = useState(null);
    const handleOpenConfirmNotification = (id) => {
        setIdNotification(id);
        setOpenConfirmNotificationAgenda(true);
    };
    const handleClosePopUp = () => {
        setOpenConfirmNotificationAgenda(false);
    };
    const [loader, setLoader] = useState(false);
    const [rows, setRows] = useState([]);
    const [agendaControl, setAgendaControl] = useState(false);
    const [sortModel, setSortModel] = React.useState([
        {field: 'createdAt', sort: 'desc'},
    ]);


    useEffect(() => {

        setLoader(true);
        agendaService.getAllAgenda().then((response) => {
            setRows(response.data.allAgenda);
            setAgendaControl(response.data.agendaIsActive);
            console.log(response.data)
            setLoader(false);
        });
    }, []);

    const [paginationModel, setPaginationModel] = useState({
        pageSize: 50,
        page: 0,
    });
    const isValidatedStatus = (is_active) => {
        if (is_active === false || is_active === null) {
            return {status: "Brouillon", color: "#f44336", icon: <Error/>};
        } else if (is_active === true) {
            return {status: "Publié", color: "#4caf50", icon: <CheckCircle sx={{color: "green"}}/>};
        }
    }

    const StatusComponent = ({isActive}) => {
        const {status, icon} = isValidatedStatus(isActive);

        return (
            <div style={{padding: '6px', borderRadius: '5px', display: 'inline-flex', alignItems: 'center'}}>
                {icon}
                <Typography style={{marginLeft: '5px'}}>{status}</Typography>
            </div>
        );
    };


    const columnsAgenda = [
        {
            field: "actions",
            headerName: "#",
            width: 130,
            renderCell: (params) => {

                return (
                    <Action
                        isAgenda
                        handleOpenConfirmNotification={handleOpenConfirmNotification}
                        notificationActivate={true}
                        editActivate={true}
                        setRows={setRows}
                        rows={rows}
                        editLink={`/agenda/edit/${params.id}`}
                        idArticle={params.row.id}
                        notifications={params.row.notification}
                        label="Êtes-vous sûr de vouloir supprimer cet article ?"
                    />
                );
            },
        },
        {
            field: "title",
            headerName: "Titre",
            width: 380,
            hideable: false
        },
        {
            field: "createdAt",
            headerName: "Créé le",
            minWidth: 200,
            valueFormatter: (params) => {
                const date = new Date(params.value);
                const options = {
                    weekday: "long",
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                };
                let localDateString = date.toLocaleDateString("fr-FR", options);
                localDateString = localDateString.replace(
                    /(\w)(\w*)/g,
                    function (g0, g1, g2) {
                        return g1.toUpperCase() + g2.toLowerCase();
                    }
                ); // Up the first letter in Uppercase
                return localDateString;
            },
        },
        {
            field: "publication_date",
            headerName: "Date de publication",
            width: 200,
            valueFormatter: (params) => {
                const date = new Date(params.value);
                const options = {
                    weekday: "long",
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                };
                let localDateString = date.toLocaleDateString("fr-FR", options);
                localDateString = localDateString.replace(
                    /(\w)(\w*)/g,
                    function (g0, g1, g2) {
                        return g1.toUpperCase() + g2.toLowerCase();
                    }
                ); // Up the first letter in Uppercase
                return localDateString;
            },
            valueGetter: (params) => {

                const dateStr = params.value

                const date = new Date(dateStr * 1000);
                return date
            }
        },
        {
            // field: "is_active",
            headerName: "Status",
            width: 150,
            renderCell: (params) => {

                return <StatusComponent isActive={params.row.is_active}/>

            },
            valueGetter: (params) => {
                return params.row.is_active
            },
        },
        {
            field: "delete",
            headerName: "",
            width: 70,
            renderCell: (params) => {
                return (
                    <Action
                        trashIcon={true}
                        deleteAction={() => handleDeleteArticle(params.id)}// to finish when ws is finish

                    />
                );
            },
        },

    ];
    const handleDeleteArticle = (id) => {

        agendaService.deleteAgendaEvent(id)
            .then((res) => {
                if (res.status === 200) {
                    setRows(rows.filter((row) => row.id !== id));
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }
    const handleNotificationClickAgenda = () => {
        agendaService.sendNotificationById(idNotification)
            .then((response) => {
                if (response.data) {
                    toast.success("Notification envoyée avec succès", {
                        position: 'top-right',
                        theme: "dark"
                    })
                    setRows(rows.map((row) => {
                        if (row.id === idNotification) {
                            return {...row, notification: response.data.nbNotification};
                        }
                        return row;
                    }));
                    handleClosePopUp();
                } else {
                    handleClosePopUp();
                    toast.error("Échec de l'envoi de la notification", {
                        position: 'top-right',
                        theme: "dark"
                    })
                }
            })
            .catch((error) => {
                setLoader(false);
                handleClosePopUp();
                const errorMessage = error.response?.data?.message ? error.response.data.message : "";
                toast.error(`Échec de l'envoi de la notification ${errorMessage === "Article published not found" ? "L'article n'est pas publié" : ""}`, {
                    position: 'top-right',
                    theme: "dark"
                })
            });
    }

    const label = {inputProps: {'aria-label': 'Switch demo'}};

    return (
        <>
            <div className={"px-3 flex flex-col py-2"}>
                <Alert severity="info">
                    <AlertTitle>Section Agenda</AlertTitle>
                    <div>

                    </div>
                    <Switch
                        color="secondary"
                        {...label}
                        checked={!!agendaControl} // Utilisation de !! pour forcer une valeur booléenne
                        onChange={() => {
                            const newAgendaControl = !agendaControl; // Inverse la valeur actuelle
                            agendaService.agendaControl(newAgendaControl)
                                .then((response) => {
                                    if (response.status === 200) {
                                        setAgendaControl(newAgendaControl); // Met à jour l'état local
                                        toast.success(
                                            newAgendaControl
                                                ? "Section Agenda activée avec succès"
                                                : "Section Agenda désactivée avec succès",
                                            {
                                                position: 'top-right',
                                                theme: "dark",
                                            }
                                        );
                                    }
                                })
                                .catch(() => {
                                    toast.error("Une erreur s'est produite. Veuillez réessayer.", {
                                        position: 'top-right',
                                        theme: "dark",
                                    });
                                });
                        }}
                    />


                    <strong>Activez ou désactivez la section Agenda du site internet à l'aide du commutateur
                    </strong>
                </Alert>


            </div>
            <AlertDialog
                open={openConfirmNotificationAgenda}
                handleClose={handleClosePopUp}
                handleAgree={() => handleNotificationClickAgenda()}
                label={"Êtes-vous sûr de vouloir envoyer une notification?"}
                title="Envoyer une notification"
                buttonAccept="Envoyer"
                buttonCancel="Annuler"
            />

            <AlertDialog title="Supression d'articles"/>
            {loader ? (
                <Container className="d-flex justify-content-center">
                    <CircularProgress className="mx-5" color="inherit"/>
                </Container>
            ) : (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <StripedDataGrid
                            style={{maxWidth: "100%"}}
                            rows={rows}
                            columns={columnsAgenda}
                            paginationModel={paginationModel}
                            onPaginationModelChange={setPaginationModel}
                            slots={{
                                toolbar: GridToolbar,
                            }}
                            sortModel={sortModel}
                            onSortModelChange={(model) => {
                                console.log(model)
                                setSortModel(model)
                            }}
                            className="mx-3"
                            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                        />
                    </Grid>
                </Grid>

            )}
        </>

    )
}

export default AgendaListEvents;
