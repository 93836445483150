import instance from "../utils/axios";

class media {
  constructor() {
    this.instance = instance;
  }

  uploadMedia = async (data) => {
    const formData = new FormData();
    formData.append("file", data);
    formData.append("type", "image");

    try {
      const response = await instance.post(
        "media/upload-image",

        formData,

        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      return response.data;
    } catch (error) {
      console.log("error", error);
    }
  };
  uploadMultipleMedia = async (data) => {

    try {
      const response = await instance.post(
        "media/upload-images",

        data,

        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true,
        }
      );

      return response.data;
    } catch (error) {
      console.log("error", error);
    }
  };

  mediaWithTag = async () => {
    return await instance.get('media/themes-media');
  }

  mediaByTheme = async (id) => {
    return await instance.get(`media/theme-media?id=${id}`)
  }
  onlyImage = async () => {
    return await instance.get('media/only-image');
  }
  updateMedia = async (data) => {
    return await instance.put('media/update', data);
  }
  deleteMedia = async (id) => {
    return await instance.delete(`media/delete?id=${id}`);
  }

  updateTagById = async ({ id, data }) => {
    return await instance.put(`tags/update/${id}`, {
      name: data.trim()
    });
  }


}


var mediaService = new media();
export default mediaService;
