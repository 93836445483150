import Menu from "../../components/layout/Menu";
import { useState, useEffect } from 'react'
import { Grid, Container, CircularProgress } from '@mui/material'
import { StripedDataGrid } from '../../constants/tableStyle'
import Action from '../../components/Action'
import { frFR, GridToolbar } from '@mui/x-data-grid'
import regiePubService from "../../services/regiePub.service";
import ConvertDateToMilliseconds from "../../common/DateConvert";


const breadcrumbArray = [
    { name: "Listing des campagnes", to: "/regie-pub" },

];
const RegiePub = () => {
    const [rows, setRows] = useState([]);
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 50,
        page: 0,
    });
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        setLoader(true);
        regiePubService.getAllPub().then((response) => {
            // Tri des données du plus récent au plus ancien
            const sortedRows = response.data.sort((a, b) => {
                const dateA = new Date(convertToISO(a.date_start));
                const dateB = new Date(convertToISO(b.date_start));
                return dateB - dateA;
            });
            setRows(sortedRows);
            setLoader(false);
        });
    }, []);

    // Fonction de conversion de la date au format ISO (from WS)
    const convertToISO = (dateStr) => {
        const [day, month, yearTime] = dateStr.split('/');
        const [year, time] = yearTime.split(' ');
        const [hour, minute, second] = time ? time.split(':') : ['00', '00', '00'];
        return `${year}-${month}-${day}T${hour}:${minute}:${second}`;
    };

    const columnsRegiePub = [
        {
            field: "actions",
            headerName: "#",
            width: 130,
            renderCell: (params) => {
                return (
                    <Action
                        isAgenda
                        // handleOpenConfirmNotification={handleOpenConfirmNotification}
                        // notificationActivate={true}
                        editActivate={true}
                        setRows={setRows}
                        rows={rows}
                        editLink={`/regie-pub/edit/${params.id}`}
                        idArticle={params.row.id}
                        notifications={params.row.notification}
                        label="Êtes-vous sûr de vouloir supprimer cet article ?"
                    />
                );
            },
        },
        {
            field: "advertiser",
            headerName: "Annonceurs",
            width: 380,
            hideable: false
        },
        {
            field: "title",
            headerName: "Nom de la campagne",
            width: 380,
            hideable: false
        },
        {
            field: "date_start",
            headerName: "Date début campagne",
            minWidth: 200,
            valueGetter: (params) => {
                return new Date(convertToISO(params.value));
            },
            valueFormatter: (params) => {
                const date = new Date(params.value)
                const options = {
                    day: "numeric",
                    month: "numeric",
                    year: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric",
                };
                let localDateString = date.toLocaleDateString("fr-FR", options);// Up the first letter in Uppercase
                return localDateString;
            }
        },
        {
            field: "date_end",
            headerName: "Date fin campagne",
            minWidth: 200,
            valueGetter: (params) => {
                return new Date(convertToISO(params.value));
            },
            valueFormatter: (params) => {
                const date = new Date(params.value)
                const options = {
                    day: "numeric",
                    month: "numeric",
                    year: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric",
                };
                let localDateString = date.toLocaleDateString("fr-FR", options);// Up the first letter in Uppercase
                return localDateString;
            }
        },
        {
            headerName: "Status",
            width: 150,
            valueGetter: (params) => {
                const dateNow = Math.round(new Date().getTime() / 1000);
                const date_start = ConvertDateToMilliseconds(params.row.date_start);
                const date_end = ConvertDateToMilliseconds(params.row.date_end);
                const is_active = params.row.is_active;

                switch (is_active) {
                    case (dateNow < date_start && is_active === true):
                        return "A venir";
                        break;
                    case (dateNow > date_end && is_active === true):
                        return "Terminé";
                        break;
                    case (is_active === false):
                        return "Brouillon";
                        break;
                    case (dateNow < date_end && is_active === true):
                        return "En ligne";
                        break;
                    }
            },
            renderCell: (params) => {
                const dateNow = Math.round(new Date().getTime() / 1000);
                const date_start = ConvertDateToMilliseconds(params.row.date_start);
                const date_end = ConvertDateToMilliseconds(params.row.date_end);
                const is_active = params.row.is_active;
                const getStatus = () => {
                    if (dateNow < date_start && is_active === true) {
                        return "A venir"
                    } else if (dateNow > date_end && is_active === true) {
                        return "Terminé"
                    } else if (is_active === false) {
                        return "Brouillon"
                    }
                    else if (dateNow < date_end && is_active === true) {
                        return "En ligne"
                    }
                }
                return getStatus();
            },

        },
        {
            field: "delete",
            headerName: "",
            width: 70,
            renderCell: (params) => {
                return (
                    <Action
                        trashIcon={true}
                        deleteAction={() => handleDeletePub(params.id)}

                    />
                );
            },
        },

    ];
    const handleDeletePub = (id) => {

        regiePubService.deletePub(id).then((response) => {
            setRows(rows.filter((row) => row.id !== id));
        });

    }

    // const AddBanner = () => {


    return (
        <Menu category="Régie Pub" breadCrumb={breadcrumbArray} link="/regie-pub/create" buttonText="Créer une campagne" >
            {loader ? (
                <Container className="d-flex justify-content-center">
                    <CircularProgress className="mx-5" color="inherit" />
                </Container>
            ) : (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <StripedDataGrid
                            style={{ maxWidth: "100%" }}
                            rows={rows}
                            columns={columnsRegiePub}
                            paginationModel={paginationModel}
                            onPaginationModelChange={setPaginationModel}
                            slots={{
                                toolbar: GridToolbar,
                            }}
                            className="mx-3"
                            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                        />
                    </Grid>
                </Grid>

            )}

        </Menu>
    );
}

export default RegiePub;